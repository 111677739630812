import { APICore } from "./apiCore";
import config from "../../config";

const api = new APICore();

// account
function login(params: { email: string; password: string }) {
  const baseUrl = "/login/";
  return api.create(`${baseUrl}`, {user: params});
}

function logout() {
  const baseUrl = "/logout/";
  return api.create(`${baseUrl}`, {});
}

function signup(params: { name: string; email: string; password: string }) {
  const baseUrl = "/signup/";
  return api.create(`${baseUrl}`, {user: params});
}

function forgotPassword(params: { email: string }) {
  const baseUrl = "/password/";
  return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword };
