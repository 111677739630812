import { APICore } from "./apiCore";
import axios from "axios";

const api = new APICore();

function getTickersData(params: {}) {
    const baseUrl = "/api/v1/tickers";
    return api.get(`${baseUrl}`, params);
}

function getStockMarketOptions(params: {}) {
    const baseUrl = "/api/v1/ticker_options";
    return api.get(`${baseUrl}`, params);
}


export { getTickersData, getStockMarketOptions };