import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// custom hook
import { useRedux } from "../hooks/";

// All layouts containers
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";

// routes
import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from "./index";
import { APICore } from "../helpers/api/apiCore";

// eslint-disable-next-line
interface IRoutesProps {}

const AllRoutes = (props: IRoutesProps) => {
  const { appSelector } = useRedux();
  const { layout } = appSelector((state) => ({
    layout: state.Layout,
  }));

  const Layout = VerticalLayout;
  const api = new APICore();

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <DefaultLayout {...props} layout={layout}>
                  {route.element}
                </DefaultLayout>
              }
              key={idx}
            />
          ))}
        </Route>

        <Route>
          {authProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                api.isUserAuthenticated() === false ? (
                  <Navigate
                    to={{
                      pathname: "/auth/login",
                      search: "next=" + route.path,
                    }}
                  />
                ) : (
                  <Layout {...props}>{route.element}</Layout>
                )
              }
              key={idx}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default AllRoutes;
