import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import filterReducer from "./filters/reducers";

export default combineReducers({
  Auth,
  Layout,
  filterReducer,
});
