import React from "react";

import AllRoutes from "./routes/Routes";

// Themes
// For Icons
import "./assets/scss/icons.scss";

// For Default import Default.scss
import "./assets/scss/config/default/app.scss";
import "./assets/scss/config/default/bootstrap.scss";

const App = () => {
  return (
    <>
      <React.Fragment>
        <AllRoutes />
      </React.Fragment>
    </>
  );
};

export default App;
